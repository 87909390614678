import React, { useState, useEffect, useMemo } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Approvalworkflow.css';

const ApprovalFlowNavTabs = ({ dateRange, approvalData, lydoGroupId }) => {
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    const start = moment(dateRange.startDate, 'DD/MM/YYYY');
    const end = moment(dateRange.endDate, 'DD/MM/YYYY');
    const datesTabs = [];

    for (let m = moment(start); m.diff(end, 'days') <= 0; m.add(1, 'days')) {
      datesTabs.push(m.format('DD/MM/YYYY'));
    }

    setTabs(datesTabs);
  }, [dateRange]);

  const filteredApprovalData = useMemo(() => {
    if (!approvalData) return {};

    return Object.fromEntries(
      Object.entries(approvalData).map(([date, dateData]) => [
        date,
        {
          ...dateData,
          approvalFlows: Object.fromEntries(
            Object.entries(dateData.approvalFlows).filter(([deptName]) => {
              const reasons = dateData.reasonsAndQuantities[deptName]?.reasons || [];
              return reasons.some(reason => reason.lydoGroupId === lydoGroupId);
            })
          )
        }
      ])
    );
  }, [approvalData, lydoGroupId]);

  const calculateTotalApprovedAndRegistered = (date) => {
    const dateData = filteredApprovalData[date];
    if (!dateData) return { totalApproved: 0, totalRegistered: 0 };

    let totalApproved = 0;
    let totalRegistered = 0;

    for (const deptName in dateData.reasonsAndQuantities) {
      const reasons = dateData.reasonsAndQuantities[deptName]?.reasons || [];
      reasons.forEach(reason => {
        if (reason.lydoGroupId === lydoGroupId) {
          totalRegistered += reason.totalRegistered || 0;
          totalApproved += reason.totalApproved || 0;
        }
      });
    }

    return { totalApproved, totalRegistered };
  };

  const renderApprovalFlow = (date) => {
    const dateData = filteredApprovalData[date];
    if (!dateData) return null;

    return (
      <div className="approval-flows-container">
        {Object.entries(dateData.approvalFlows).map(([deptName, flow], index) => {
          const reasons = dateData.reasonsAndQuantities[deptName]?.reasons || [];
          const filteredReasons = lydoGroupId
            ? reasons.filter(reason => reason.lydoGroupId === lydoGroupId)
            : reasons;

          const totalRegistered = dateData.reasonsAndQuantities[deptName]?.count || 0;
          const totalApproved = dateData.reasonsAndQuantities[deptName]?.totalApproved || 0;

          return (
            <div key={deptName} className="approval-flow-container mb-4">
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <div>
                    <h4 className="text-primary">Đề xuất của: {deptName.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</h4>
                    <p>Bộ phận: {filteredReasons.map(reason => reason.deptName).join(', ')}</p>
                  </div>
                  <div>
                    {filteredReasons.map((group, idx) => (
                      <React.Fragment key={idx}>
                        {idx > 0 && <hr className="reason-separator" />}
                        <div className="reason-item mb-2 reason-badges-container">
                          <span className="badge rounded-pill text-bg-info m-2 mt-2">Lý do: {group.reason}</span>
                          <span className="badge text-bg-secondary m-2 mt-2">Mã hàng: {group.mahang}</span>
                          <span className="badge rounded-pill text-bg-success m-2 mt-2">Khách hàng: {group.khachhang}</span>
                          <span className="badge rounded-pill text-bg-warning m-2 mt-2">Nhà ăn: {group.nhaan}</span>
                          <span className="badge rounded-pill text-bg-danger m-2 mt-2">Máy nén khí: {group.maynenkhi}</span>
                          <span className="badge rounded-pill text-bg-dark m-2 mt-2" title="Thời gian đăng ký ăn ca">Thời gian: {group.timeRanges}</span>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
                <div className="card-body">
                  {/* {filteredReasons.map((group, idx) => (
                    <div key={idx} className="duration-item mb-2">
                      <span className="badge text-bg-dark" title="Thời gian đăng ký ăn ca">Thời gian: {group.timeRanges}</span>
                    </div>
                  ))} */}
                  <div className="approval-flow mt-4 mb-4 d-flex justify-content-between ">
                    {flow.map((step, index) => {
                    const isCompleted = index < dateData.currentSteps[deptName];
                    const isCurrent = index === dateData.currentSteps[deptName];
                    const isFinal = step.isFinal;
                    
                    const logData = dateData.approvalLogs[deptName]?.[step.level] || {};
                    const approvedCount = logData.totalApproved || 0;
                    const pendingCount = logData.pendingApproval || 0;
                      return (
                      <div key={index} className={`approval-step ${isCompleted ? 'completed' : ''} ${isCurrent ? 'current' : ''}`}>
                        <div className="step-circle">
                          <div className="step-number">{step.level}</div>
                        </div>
                        <div className="step-content">
                          <div className="approver-name">{step.approverName}</div>
                          <div className="approved-count">Đã duyệt: {approvedCount}</div>
                          <div className="pending-count">Chờ duyệt: {pendingCount}</div>
                          <small className="d-block text-muted fw-bolder">
                            {logData.createdAt && (() => {
                              const date = new Date(logData.createdAt);
                              const dateStr = date.toLocaleDateString('vi-VN', { day: '2-digit', month: '2-digit',year:'numeric'}).replace('-', '/');
                              const timeStr = date.toLocaleTimeString('vi-VN', { hour: '2-digit', minute: '2-digit', hour12: false });
                              return `${dateStr} ${timeStr} `;
                            })()}
                          </small>
                          {isFinal && <div className="final-step">{isCompleted ? 'Đã duyệt' : 'Đang đợi duyệt'}</div>}
                        </div>
                      </div>
                    );
                  })}
                  </div>
                </div>
              </div>
              {index < Object.entries(dateData.approvalFlows).length - 1 && <hr className="approval-flow-separator" />}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Tabs className="nav-tabs-container">
      <TabList className="nav nav-tabs">
        {tabs.map((date) => {
          const { totalApproved, totalRegistered } = calculateTotalApprovedAndRegistered(date);
          return (
            <Tab key={date} className="nav-item">
              <span className="nav-link">
                {date}
                <br />
                <small className="text-danger">Đăng ký: {totalRegistered}/ Tổng đã duyệt: {totalApproved}</small>
              </span>
            </Tab>
          );
        })}
      </TabList>

      {tabs.map((date) => (
        <TabPanel key={date} className="tab-content">
          <div className="tab-pane fade show active">
            {renderApprovalFlow(date)}
          </div>
        </TabPanel>
      ))}
    </Tabs>
  );
};

export default ApprovalFlowNavTabs;
