import React, { useState, useEffect,useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../assets/style.css';
import { UserCircle, Key, LogOut, Bell,MessageCircle,X } from 'lucide-react'; 
import { Modal, Button } from 'react-bootstrap';
import logo from '../assets/images/logocty.jpg';
import { API_URL } from '../authlogin/api';
import { messaging } from './firebase';
import { getToken } from "firebase/messaging";
import { onMessage } from "firebase/messaging"; 
import { ToastContainer, toast } from 'react-toastify';
import { vapidKey } from '../authlogin/api';
const NavTabs = () => {
  const navigate = useNavigate();
  const [TenNV, setTenNV] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isAuthenticated = !!localStorage.getItem('token');
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [capDo, setcapDo] = useState(null);
  // const [showChatIcon, setShowChatIcon] = useState(true);
  // const [showChatPopup, setShowChatPopup] = useState(false);
  // const chatIconRef = useRef(null);
  // const chatPopupRef = useRef(null);

  // const chatLink = "https://chat.vietlonghung.com.vn"; // Replace with your actual chat link

  const MaNV = localStorage.getItem('MaNV');
  useEffect(() => {
  const storedTenNV = localStorage.getItem('TenNV');
  const storedcapDo = localStorage.getItem('capDo');
  if (storedTenNV) {
    setTenNV(storedTenNV);
  }
  if (storedcapDo) {
    setcapDo(parseInt(storedcapDo));
  }
  
}, [isAuthenticated]);

useEffect(() => {
  if (isAuthenticated) {
    fetchNotifications();
    const interval = setInterval(fetchNotifications, 10000); // Cập nhật mỗi 10 giây
    return () => clearInterval(interval);
  }
}, [isAuthenticated]);
  // useEffect(() => {
  //   document.addEventListener('click', handleDocumentClick);
  //   return () => {
  //     document.removeEventListener('click', handleDocumentClick);
  //   };
  // }, []);
  useEffect(() => {
    if (isAuthenticated) {
      requestNotificationPermission();
    }
  }, [isAuthenticated]);
  useEffect(() => {
  const unsubscribe = onMessage(messaging, (payload) => {
    // console.log('Message received. ', payload);
    toast.info(payload.data.body, {
      onClick: () => {
        if (payload.data.url) {
          navigate(payload.data.url);
        }
      }
    });
  });

  return () => {
    unsubscribe();
  };
}, [navigate]);

  const getPlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      return 'android';
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'ios';
    }
    return 'web';
  };
  

  const requestNotificationPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        const token = await getToken(messaging, { vapidKey });
        await sendTokenToServer(token);
      } else {
        console.log('Notification permission denied');
      }
    } catch (error) {
      console.error('Error requesting notification permission:', error);
    }
  };
  
  const sendTokenToServer = async (token) => {
    try {
      const platform = getPlatform();
      await axios.post(`${API_URL}/register-device`, { token, platform }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
    } catch (error) {
      console.error('Error sending token to server:', error);
    }
  };
  // const handleDocumentClick = (event) => {
  //   if (chatIconRef.current && chatIconRef.current.contains(event.target)) {
  //     // Click is on the chat icon, toggle the popup
  //     setShowChatPopup(prevState => !prevState);
  //   } else if (chatPopupRef.current && !chatPopupRef.current.contains(event.target)) {
  //     // Click is outside the popup, close it
  //     setShowChatPopup(false);
  //   }
  // };
  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${API_URL}/notifications`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setNotifications(response.data.notifications);
      setNotificationCount(response.data.notifications.filter(n => !n.read).length);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };
  const unregisterDevice = async () => {
    try {
    await axios.post(`${API_URL}/unregister-device`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    console.log('Device unregistered successfully');
  } catch (error) {
    console.error('Error unregistering device:', error);
  }
};
  const handleLogout = async () => {
  const confirmLogout = window.confirm('Bạn có muốn đăng xuất không?');
  if (confirmLogout) {
    try {
      await unregisterDevice(); // Gọi hàm xóa thiết bị
      localStorage.clear();
      setTenNV('');
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
      // Vẫn tiếp tục đăng xuất ngay cả khi có lỗi xảy ra
      localStorage.clear();
      setTenNV('');
      navigate('/login');
    }
  }
};

  // const handleChatIconClick = (event) => {
  //   event.stopPropagation();
  //   setShowChatPopup(prevState => !prevState);
  // };

  // const handleCloseChatPopup = () => {
  //   setShowChatPopup(false);
  // };
  
  const handleNotificationClick = () => {
    setShowNotifications(true);
   
  };

  const handleCloseNotifications = () => {
    setShowNotifications(false);
  };

  const handleNotificationItemClick = async (notificationId,url) => {
  try {
    await axios.post(`${API_URL}/mark-notification-read/${notificationId}`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    // Cập nhật state local
    setNotifications(notifications.map(n => 
      n._id === notificationId ? { ...n, read: true } : n
    ));
    setNotificationCount(prev => Math.max(0, prev - 1));
    // Đóng modal thông báo
    setShowNotifications(false);

    // Chuyển hướng đến trang duyệt với các tham số
    // console.log('Navigating to:', url);
    if (url) {
      navigate(url);
    }
  } catch (error) {
    console.error('Error marking notification as read:', error);
  }
};

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light" style={{ display: document.title === 'Kiểm kê suất ăn' ? 'none' : 'block' }} id="neubar">
        <div className="container">
          <Link className="navbar-brand" to="/" style={{ display: 'flex', alignItems: 'center' }}>  
            <img src={logo} style={{marginRight:'10px'}} height="60" alt="Logo" />  
          <span className="navbar-brand mb-0 h1" style={{ color: '#5a454e' }}>Đăng ký ăn ca CT</span>  
        </Link> 
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto mt-3 mt-lg-0">
              {isAuthenticated ? (
                <> 
                  {capDo === 1 &&  MaNV === '07822'|| capDo === 0 && MaNV === '10673'|| capDo === 0 && MaNV === '08782' ? (
                    <>
                    <li className="nav-item">
                      <Link className={`nav-link mx-2 ${document.title === 'Xem luồng duyệt' ? 'active' : ''}`} to="/workflow">Luồng duyệt</Link>
                    </li>
                    <li className='nav-item'>
                        <Link className={`nav-link mx-2 ${document.title === 'Tổng quan' ? 'active' : ''}`} aria-current="page" to="/general">Tổng quan</Link>
                    </li>
                    <li className="nav-item">
                        <Link className={`nav-link mx-2 ${document.title === 'Tổng hợp suất ăn' ? 'active' : ''}`} to="/tonghop">Tổng hợp</Link>
                      </li>
                      <li className="nav-item">
                      <Link className={`nav-link mx-2 ${document.title === 'Nhập dữ liệu' ? 'active' : ''}`} to="/nhapdulieu">Nhập dữ liệu</Link>
                    </li>
                      </>
                  ) :
                    capDo >= 3 ? (
                        <>
                    <li className="nav-item">
                        <Link className={`nav-link mx-2 ${document.title === 'Trang chủ' ? 'active' : ''}`} aria-current="page" to="/">Trang chủ</Link>
                      </li>
                    <li className='nav-item'>
                        <Link className={`nav-link mx-2 ${document.title === 'Tổng quan' ? 'active' : ''}`} aria-current="page" to="/general">Tổng quan</Link>
                    </li>  
                    <li className="nav-item">
                        <Link className={`nav-link mx-2 ${document.title === 'Xem luồng duyệt' ? 'active' : ''}`} to="/workflow">Luồng duyệt</Link>
                    </li>
                      {/* <li className="nav-item">
                        <Link className={`nav-link mx-2 ${document.title === 'Duyệt suất ăn' ? 'active' : ''}`} aria-current="page" to="/duyetovertime">Duyệt ăn ca</Link>
                      </li> */}
                      <li className="nav-item">
                        <Link className={`nav-link mx-2 ${document.title === 'Tổng hợp suất ăn' ? 'active' : ''}`} to="/tonghop">Tổng hợp</Link>
                      </li>
                      </>) :
                      
                    capDo === 2 ? (
                        <>
                    <li className="nav-item">
                        <Link className={`nav-link mx-2 ${document.title === 'Trang chủ' ? 'active' : ''}`} aria-current="page" to="/">Trang chủ</Link>
                      </li>
                    <li className="nav-item">
                      <Link className={`nav-link mx-2 ${document.title === 'Xem luồng duyệt' ? 'active' : ''}`} to="/workflow">Luồng duyệt</Link>
                    </li>
                    
                      {/* <li className="nav-item">
                        <Link className={`nav-link mx-2 ${document.title === 'Duyệt suất ăn' ? 'active' : ''}`} aria-current="page" to="/duyetovertime">Duyệt ăn ca</Link>
                      </li> */}
                      <li className="nav-item">
                        <Link className={`nav-link mx-2 ${document.title === 'Tổng hợp suất ăn' ? 'active' : ''}`} to="/tonghop">Tổng hợp</Link>
                      </li>
                      </>
                    ):
                  capDo === 1&& MaNV !== '00055' && MaNV !== '00600' ? (
                    <li className="nav-item">
                      <Link className={`nav-link mx-2 ${document.title === 'Nhập dữ liệu' ? 'active' : ''}`} to="/nhapdulieu">Nhập dữ liệu</Link>
                    </li>
                    ) :
                      capDo === 1 && MaNV === '00055' ? (
                        <>
                    <li className="nav-item">
                      <Link className={`nav-link mx-2 ${document.title === 'Nhập dữ liệu' ? 'active' : ''}`} to="/nhapdulieu">Nhập dữ liệu</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={`nav-link mx-2 ${document.title === 'Xem luồng duyệt' ? 'active' : ''}`} to="/workflow">Luồng duyệt</Link>
                    </li>
                    <li className='nav-item'>
                        <Link className={`nav-link mx-2 ${document.title === 'Tổng quan' ? 'active' : ''}`} aria-current="page" to="/general">Tổng quan</Link>
                    </li>
                      </>
                    ) :
                      (
                    <>
                      <li className="nav-item">
                        <Link className={`nav-link mx-2 ${document.title === 'Trang chủ' ? 'active' : ''}`} aria-current="page" to="/">Trang chủ</Link>
                      </li>
                      <li className="nav-item">
                        <Link className={`nav-link mx-2 ${document.title === 'Duyệt suất ăn' ? 'active' : ''}`} aria-current="page" to="/duyetovertime">Duyệt ăn ca</Link>
                      </li>
                      <li className="nav-item">
                        <Link className={`nav-link mx-2 ${document.title === 'Kiểm kê suất ăn' ? 'active' : ''}`} to="/scanner">Kiểm kê</Link>
                      </li>
                      <li className="nav-item">
                        <Link className={`nav-link mx-2 ${document.title === 'Tổng hợp suất ăn' ? 'active' : ''}`} to="/tonghop">Tổng hợp</Link>
                      </li>
                    </>
                  )}
                  
                  <li className="nav-item dropdown">
                    <div className="nav-link" onClick={handleNotificationClick} style={{ cursor: 'pointer', position: 'relative' }}>
                      <Bell size={25} />
                      {notificationCount > 0 && (
                        <span className="position-absolute top-0  translate-middle badge rounded-pill bg-danger">
                          {notificationCount}
                          <span className="visually-hidden">unread notifications</span>
                        </span>
                      )}
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <div className="nav-link dropdown-toggle" style={{ cursor: 'pointer' }} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                      <UserCircle size={25} />
                      <span className="ms-2">{TenNV}</span>
                    </div>
                    {isMenuOpen && (
                      <ul className="dropdown-menu dropdown-menu-end show">
                        <li>
                          <Link className="dropdown-item" to="/changepassword">
                            <Key size={18} className="me-2" />
                            Đổi mật khẩu
                          </Link>
                        </li>
                        <li>
                          <button className="dropdown-item" onClick={handleLogout}>
                            <LogOut size={18} className="me-2" />
                            Đăng xuất
                          </button>
                        </li>
                      </ul>
                    )}
                  </li>
                </>
              ) : (
                <li className="nav-item">
                  <Link className="nav-link mx-2 active" to="/login">Đăng nhập</Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
      
      {/* {showChatIcon && (
        <div 
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: 1000,
            cursor: 'pointer',
            backgroundColor: 'rgb(205 99 13)',
            borderRadius: '50%',
            width: '60px',
            height: '60px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
            transition: 'transform 0.3s ease, background-color 0.3s ease', // Add transition for smooth effect
            }}
            onClick={handleChatIconClick}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = 'scale(1.1)';
              e.currentTarget.style.backgroundColor = 'rgb(235 129 43)';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = 'scale(1)';
              e.currentTarget.style.backgroundColor = 'rgb(205 99 13)';
            }}
          >
    <MessageCircle size={30} color="white" />
  </div>
      )}

      {showChatPopup && (
        <div 
          ref={chatPopupRef}
          style={{
            position: 'fixed',
            bottom: '90px',
            right: '20px',
            width: '350px',
            height: '500px',
            backgroundColor: 'white',
            borderRadius: '10px',
            boxShadow: '0 0 10px rgba(0,0,0,0.1)',
            zIndex: 1001,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column'
          }}
          
        >
          <div style={{ 
            padding: '10px', 
            backgroundColor: '#007bff', 
            color: 'white',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <span>Chat</span>
            <X 
              size={20} 
              style={{ cursor: 'pointer' }} 
              onClick={handleCloseChatPopup}
            />
          </div>
          <iframe 
            src={chatLink} 
            style={{
              width: '100%',
              height: '100%',
              border: 'none'
            }}
          />
        </div>
      )} */}
      <Modal show={showNotifications} onHide={handleCloseNotifications}>
      <Modal.Header closeButton>
        <Modal.Title>Thông báo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {notifications.length > 0 ? (
          <ul className="list-group">
            {notifications.map((notification) => (
              <li 
                key={notification._id} 
                className={`list-group-item ${notification.read ? 'text-muted' : 'fw-bold'}`}
                onClick={() =>
                  handleNotificationItemClick(notification._id, notification.url)}
                style={{ cursor: 'pointer' }}
              >
                {notification.message}
                <small className="d-block text-muted colerr">
                  {new Date(notification.createdAt).toLocaleString('vi-VN')}
                </small>
              </li>
            ))}
          </ul>
        ) : (
          <p>Không có thông báo mới.</p>
          )}
          
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseNotifications}>
          Đóng
        </Button>
      </Modal.Footer>
      </Modal>
    </>
  );
};

export default NavTabs;