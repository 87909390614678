import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import NavTabs from './NavTabs';
import "./Overview.css";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { Badge, Button, Modal } from 'react-bootstrap';
import { messaging } from './firebase';
import { getToken } from "firebase/messaging";
import { onMessage } from "firebase/messaging"; 
import { ToastContainer, toast } from 'react-toastify';
import { FaTimes } from 'react-icons/fa';
import { API_URL } from '../authlogin/api';
import { FaArrowRightArrowLeft } from 'react-icons/fa6';
import { vapidKey } from '../authlogin/api';

function Overview() {
  document.title = "Trang chủ";
  const [activeItem, setActiveItem] = useState('pending');
  const [groupedLogs, setGroupedLogs] = useState({});
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(3, 'days').format('DD/MM/YYYY'),
    endDate: moment().add(8, 'days').format('DD/MM/YYYY')
  });
  const [userInfo, setUserInfo] = useState(null);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [selectedLog, setSelectedLog] = useState(null);
  const [showUyQuyenModal, setShowUyQuyenModal] = useState(false);
  const [uyQuyen, setUyQuyen] = useState({
    tuNgay: '',
    denNgay: '',
    nguoiDuocUyQuyen: ''
  });
  const [availableUsers, setAvailableUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (userInfo) {
      fetchLogs();
    }
  }, [activeItem, dateRange, userInfo]);

  // useEffect(() => {
  //   requestNotificationPermission();
  // }, []);

  // useEffect(() => {
  //   const unsubscribe = onMessage(messaging, (payload) => {
  //     console.log('Message received. ', payload);
  //     toast.info(payload.data.body);
  //   });

  //   return () => {
  //     unsubscribe();
  //   };
  // }, []);

  // const getPlatform = () => {
  //   const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  //   if (/android/i.test(userAgent)) {
  //     return 'android';
  //   }
  //   if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
  //     return 'ios';
  //   }
  //   return 'web';
  // };
  
  // const requestNotificationPermission = async () => {
  //   try {
  //     const permission = await Notification.requestPermission();
  //     if (permission === 'granted') {
  //       const token = await getToken(messaging, { vapidKey });
  //       await sendTokenToServer(token);
  //     } else {
  //       console.log('Notification permission denied');
  //     }
  //   } catch (error) {
  //     console.error('Error requesting notification permission:', error);
  //   }
  // };
  
  // const sendTokenToServer = async (token) => {
  //   try {
  //     const platform = getPlatform();
  //     await axios.post(`${API_URL}/register-device`, { token, platform }, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem('token')}`
  //       }
  //     });
  //   } catch (error) {
  //     console.error('Error sending token to server:', error);
  //   }
  // };

  const fetchUserInfo = async () => {
    try {
      const response = await axios.get(`${API_URL}/user-info`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setUserInfo(response.data);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

   const fetchLogs = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_URL}/logs`, {
        params: {
          status: activeItem,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate
        },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      const sortedLogs = response.data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setGroupedLogs(sortedLogs);
    } catch (error) {
      console.error('Error fetching logs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleItemClick = (item) => {
    if (!isLoading) {
      setActiveItem(item);
    }
  };
  const fetchAvailableUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}/available-users-for-delegation`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    });
    setAvailableUsers(response.data);
  } catch (error) {
    console.error('Error fetching available users:', error);
    toast.error('Không thể lấy danh sách người dùng');
  }
  };
  const openUyQuyenModal = () => {
    setShowUyQuyenModal(true);
    fetchAvailableUsers();
  };
  

  const handleDateChange = (event, picker) => {
    setDateRange({
      startDate: picker.startDate.format('DD/MM/YYYY'),
      endDate: picker.endDate.format('DD/MM/YYYY')
    });
  };

  const handleReject = (log) => {
    setSelectedLog(log);
    setShowRejectModal(true);
  };

  const handleRejectConfirm = async () => {
    try {
      await axios.post(`${API_URL}/reject-overtime`, {
        logId: selectedLog.id,
        reason: rejectReason,
        startDate: selectedLog.startDate,
        endDate: selectedLog.endDate
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
  
      // Cập nhật trạng thái của tất cả các log trong khoảng thời gian
      setGroupedLogs(prevLogs => {
        return prevLogs.map(log => {
          if (moment(log.startDate, 'DD/MM/YYYY').isSameOrAfter(moment(selectedLog.startDate, 'DD/MM/YYYY')) &&
              moment(log.endDate, 'DD/MM/YYYY').isSameOrBefore(moment(selectedLog.endDate, 'DD/MM/YYYY'))) {
            return {
              ...log,
              tuchoi: true,
              daduyet: false
            };
          }
          return log;
        });
      });
  
      toast.success('Đã từ chối yêu cầu ăn ca');
      setShowRejectModal(false);
      setRejectReason('');
      await fetchLogs();
    } catch (error) {
      console.error('Error rejecting overtime:', error);
      toast.error('Có lỗi xảy ra khi từ chối yêu cầu ăn ca');
    }
  };
  // Thêm hàm mới để xử lý việc đăng ký lại
  const handleReapply = async (log) => {
    try {
      await axios.post(`${API_URL}/reapply-overtime`, {
        logId: log.id,
        startDate: log.startDate,
        endDate: log.endDate
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      toast.success('Đã đăng ký lại yêu cầu ăn ca');
      await fetchLogs();
    } catch (error) {
      console.error('Error reapplying overtime:', error);
      toast.error('Có lỗi xảy ra khi đăng ký lại yêu cầu ăn ca');
    }
  };
  const handleUyQuyen = async () => {
      try {
        const response = await axios.post(`${API_URL}/update-uy-quyen`, uyQuyen, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        toast.success('Cập nhật ủy quyền thành công');
        setShowUyQuyenModal(false);
      } catch (error) {
        console.error('Lỗi khi cập nhật ủy quyền:', error);
        toast.error('Có lỗi xảy ra khi cập nhật ủy quyền');
      }
    };

  const getStatus = (group) => {
    if (group.tuchoi) return "rejected";
    if (group.daduyet) return "approved";
    return "pending";
  };
  
  const getStatusBadge = (status) => {
    switch (status) {
      case "rejected":
        return <Badge bg="danger">Đã từ chối <FaTimes /></Badge>;
      case "approved":
        return <Badge bg="success">Đã duyệt</Badge>;
      case "pending":
        return <Badge bg="warning">Chờ duyệt</Badge>;
      default:
        return null;
    }
  };

  return (
    <>
      <NavTabs />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 d-flex justify-content-end">
            {userInfo && userInfo.capCuoi && (
             <Button variant="info" className='text-white' onClick={openUyQuyenModal}>
              <FaArrowRightArrowLeft/> Ủy quyền 
            </Button>
            )}
          </div>
          <Modal show={showUyQuyenModal} onHide={() => setShowUyQuyenModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Ủy quyền duyệt</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-group">
                <label>Từ ngày:</label>
                <input
                  type="date"
                  className="form-control"
                  value={uyQuyen.tuNgay}
                  onChange={(e) => setUyQuyen({...uyQuyen, tuNgay: e.target.value})}
                />
              </div>
              <div className="form-group">
                <label>Đến ngày:</label>
                <input
                  type="date"
                  className="form-control"
                  value={uyQuyen.denNgay}
                  onChange={(e) => setUyQuyen({...uyQuyen, denNgay: e.target.value})}
                />
              </div>
              <div className="form-group">
                <label>Người được ủy quyền:</label>
                <select
                  className="form-control"
                  value={uyQuyen.nguoiDuocUyQuyen}
                  onChange={(e) => setUyQuyen({...uyQuyen, nguoiDuocUyQuyen: e.target.value})}
                >
                  <option value="">Chọn người được ủy quyền</option>
                  {availableUsers.map(user => (
                    <option key={user._id} value={user._id}>{user.TenNV}</option>
                  ))}
                </select>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowUyQuyenModal(false)}>
              Hủy
            </Button>
            <Button variant="primary" onClick={handleUyQuyen}>
              Xác nhận ủy quyền
            </Button>
          </Modal.Footer>
        </Modal>
          <main className="col-sm-12 ms-sm-auto px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Đề xuất ăn ca</h1>
              <div className="btn-toolbar mb-2 mb-md-0">
                <div className="btn-group me-2">
                  <DateRangePicker
                    initialSettings={{
                      startDate: dateRange.startDate,
                      endDate: dateRange.endDate,
                      maxDate: moment('DD/MM/YYYY'),
                      locale: {
                        format: 'DD/MM/YYYY'
                      }
                    }}
                    onApply={handleDateChange}
                  >
                    <button type="button" className="btn btn-sm btn-outline-secondary">
                      {dateRange.startDate} - {dateRange.endDate}
                    </button>
                  </DateRangePicker>
                </div>
              </div>
            </div>

            <div className="filter-container">
        <h4>Lọc theo trạng thái</h4>
        <div className="filter-options">
          <label className={`filter-option ${activeItem === 'all' ? 'active' : ''}`}>
            <input
              type="radio"
              name="filter"
              value="all"
              checked={activeItem === 'all'}
              onChange={() => handleItemClick('all')}
              disabled={isLoading}
            />
            <span>Tất cả</span>
          </label>
          <label className={`filter-option ${activeItem === 'approved' ? 'active' : ''}`}>
            <input
              type="radio"
              name="filter"
              value="approved"
              checked={activeItem === 'approved'}
              onChange={() => handleItemClick('approved')}
              disabled={isLoading}
            />
            <span>Đã duyệt</span>
          </label>
          <label className={`filter-option ${activeItem === 'pending' ? 'active' : ''}`}>
            <input
              type="radio"
              name="filter"
              value="pending"
              checked={activeItem === 'pending'}
              onChange={() => handleItemClick('pending')}
              disabled={isLoading}
            />
            <span>Chờ duyệt</span>
          </label>
        </div>
      </div>

      <div className="table-responsive">
              {isLoading ? (
                <div className="text-center">
                  <p>Đang tải dữ liệu...</p>
                </div>
              ) : (
                <table className="table table-striped table-sm responsiveTabl">
                
                  <thead>
                    <tr>
                      <th>Ngày ăn ca</th>
                      <th>Tên đề xuất</th>
                      <th>Phòng ban</th>
                      <th>Người đề xuất</th>
                      <th>Tổng số lượng</th>
                      <th>Thời gian</th>
                      <th>Lý do</th>
                      <th>Trạng thái</th>
                      {userInfo && userInfo.capDo !== 2 && (
                        <th>Hành động</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(groupedLogs).map((group, groupIndex) => {
                      const status = getStatus(group);
                      return (
                        <tr
                          key={groupIndex}
                          className={status === "rejected" ? 'rejected-row' : ''}
                        >
                          <td data-label="Ngày ăn ca">{`${group.startDate} - ${group.endDate}`}</td>
                          <td data-label="Tên đề xuất">
                            <Link to={`/duyetovertime?lydoGroupId=${group.lydoGroupId}&showApprovalFlow=true&startDate=${group.startDate}&endDate=${group.endDate}`}>
                              {group.tendexuat}
                            </Link>
                          </td>
                          <td data-label="Phòng ban">{group.department}</td>
                          <td data-label="Người đề xuất">{group.nguoidexuat}</td>
                          <td data-label="Tổng số lượng">{group.soluong}</td>
                          <td data-label="Thời gian">{group.timeRangeKey}</td>
                          <td data-label="Lý do">{group.lydo}
                            {group.lydotuchoi && (
                              <span className="rejection-reason">
                                <br />
                                <strong style={{ color: 'red' }}>Lý do từ chối:{group.lydotuchoi}</strong>
                              </span>
                            )}
                          </td>
                          <td data-label="Trạng thái">{getStatusBadge(status)}</td>
                          {userInfo && userInfo.capDo !== 2 && (
                            <td data-label="Hành động">
                              {status === "pending" && (
                                <Button variant="danger" size="sm" onClick={() => handleReject(group)}>
                                  Từ chối
                                </Button>
                              )}
                              {status === "rejected" && group.canReapply && (
                                <Button variant="primary" size="sm" onClick={() => handleReapply(group)}>
                                  Cập nhật lại
                                </Button>
                              )}
                            
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
            <Modal show={showRejectModal} onHide={() => setShowRejectModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Từ chối yêu cầu ăn ca</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <div className="form-group">
                    <label>Lý do từ chối:</label>
                    <textarea
                      className="form-control"
                      rows="3"
                      value={rejectReason}
                      onChange={(e) => setRejectReason(e.target.value)}
                    ></textarea>
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowRejectModal(false)}>
                  Hủy
                </Button>
                <Button variant="primary" onClick={handleRejectConfirm}>
                  Xác nhận từ chối
                </Button>
              </Modal.Footer>
            </Modal>
          </main>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default Overview;