import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import '../assets/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavTabs from './NavTabs';
import { API_URL } from '../authlogin/api';
import errorSound from '../assets/buzzer-error.MP3';
function Scanner() {
  const [inputValue, setInputValue] = useState(''); 
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [entries, setEntries] = useState([]);
  const inputRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const errorsound = new Audio(errorSound);

  document.title = "Kiểm kê suất ăn";

// Xử lý khi nhấn Enter 
const handleKeyDown = async (event) => {
  if (event.key === 'Enter') {
    event.preventDefault();
    if (!isSubmitting) {
      await handleSubmit(inputValue);
    }
  }
};
//Xử lý khi nhập liệu, tự động submit khi đủ 5 ký tự
const handleInputChange = (e) => {
  let value = e.target.value;
  // Loại bỏ tất cả các ký tự không phải số
  value = value.replace(/\D/g, '');
  // Lấy 5 số cuối cùng
  value = value.slice(-5);
  setInputValue(value);
  if (value.length === 5 && !isSubmitting) {
    handleSubmit(value);
  }
};
  //Hàm này gửi request đến server, xử lý response và cập nhật state tương ứng.
  const playErrorSound = () => {
    errorsound.play().catch(error => console.error("Error playing sound:", error));
  };
 const handleSubmit = async (value) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const response = await axios.post(`${API_URL}/check-status`, { MaNV: value });
      const newEntry = {
        MaNV: value,
        TenNV: response.data.hoTen || 'Chưa có tên',
        thoiGian: moment().tz('Asia/Bangkok').toISOString(),
      };

      if (response.status === 200) {
        if (response.data.message === 'Bạn đã lấy suất ăn rồi.') {
          newEntry.status = 'warning';
          setMessageType('warning');
          setMessage(`<b>${response.data.hoTen}</b> đã lấy suất ăn rồi!`);
        } else {
          newEntry.status = 'success';
          setMessageType('success');
          setMessage(`<b>${response.data.hoTen}</b> lấy suất ăn thành công.`);
        }
        setEntries((prevEntries) => [newEntry, ...prevEntries]);
      } else {
        setMessageType('danger');
        setMessage('Error checking status.');
        playErrorSound();
      }
    } catch (error) {
      const newEntry = {
        MaNV: value,
        TenNV: error.response?.data?.hoTen || 'Chưa có tên',
        thoiGian: moment().tz('Asia/Bangkok').toISOString(),
      };
      playErrorSound();
      if (error.response) {
        if (error.response.status === 400) {
          newEntry.status = 'warning';
          setMessageType('warning');
          setMessage(`<b>${error.response?.data?.hoTen}</b> đã lấy suất ăn rồi!`);
        } else if (error.response.status === 404) {
          newEntry.status = 'danger';
          setMessageType('danger');
          setMessage(`Mã NV: ${value} chưa đăng ký suất ăn!`);
          playErrorSound();
        } else {
          setMessageType('danger');
          setMessage('Error checking status.');
          playErrorSound();
        }
        setEntries((prevEntries) => [newEntry, ...prevEntries]);
      } else {
        setMessageType('danger');
        setMessage('Error checking status.');
        playErrorSound();
      }
    } finally {
      setIsSubmitting(false);
      setInputValue('');
    }
  };
//Effect đầu cuộn trang lên đầu khi có bản ghi mới.
  useEffect(() => {
    if (entries.length > 0) {
      window.scrollTo(0, 0);
    }
  }, [entries]);
//xử lý việc ẩn/hiện navbar khi focus vào input 
  useEffect(() => {
    const handleFocus = () => {
      const nav = document.querySelector('.navbar');
      if (nav) nav.style.display = 'none';
    };

    const handleFocusOut = () => {
      const nav = document.querySelector('.navbar');
      if (nav) nav.style.display = 'block';
    };

    const inputElement = inputRef.current;
    inputElement.addEventListener('focus', handleFocus);
    inputElement.addEventListener('blur', handleFocusOut);

    return () => {
      inputElement.removeEventListener('focus', handleFocus);
      inputElement.removeEventListener('blur', handleFocusOut);
    };
  }, []);

  return (
    <div>
      <NavTabs />
      <div className="container bg-web mt-3 pt-3" style={{ fontSize: 'x-large' }}>
        <form id="frm-kiemke" >
          <div className="mb-3 row">
            <h1 className="text-center">QUÉT QRCODE</h1>
          </div>
          <div className="row">
            <div className="col">
            <input
              type="text"
              className="form-control"
              id="id-nv"
              maxLength="7"
              title="Nhập mã nhân viên hoặc quét QR trên thẻ nhân viên"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Nhập mã nhân viên hoặc quét QR trên thẻ nhân viên"
              required
              autoFocus
              ref={inputRef}
            />
              <div className="invalid-feedback">
                Nhập mã nhân viên (5 số) hoặc quét QR trên thẻ
              </div>
              {message && (
                <div className={`alert mt-2 alert-${messageType}`} role="alert">
                  <i className={`fas ${messageType === 'success' ? 'fa-check text-success' : messageType === 'warning' ? 'fa-exclamation-circle text-warning' : 'fa-ban text-danger'}`}></i>
                  <span dangerouslySetInnerHTML={{ __html: message }} />
                </div>
              )}
            </div>
          </div>
        </form>
        <div className="row caption mb-3 mt-3">
          <div className="mt-2 col-4 font-weight-bold">
            <i className="fas fa-check text-success"></i> Thành công
          </div>
          <div className="mt-2 col-4 font-weight-bold">
            <i className="fas fa-exclamation-circle text-warning"></i> Đã lấy trước đó
          </div>
          <div className="mt-2 col-4 font-weight-bold">
            <i className="fas fa-ban text-danger"></i> Không đăng ký
          </div>
        </div>
        <div style={{ maxHeight: '50vh', overflowY: 'auto' }}>
          <table className="table table-bordered table-responsive table-striped table-kiemke">
            <thead className="sticky-top">
              <tr>
                <th>Mã NV</th>
                <th>Tên NV</th>
                <th>Thời gian</th>
              </tr>
            </thead>
            <tbody>
              {entries.map((entry, index) => {
                const utcDate = moment.utc(entry.thoiGian);
                const localDate = utcDate.tz('Asia/Bangkok');
                const formattedTime = localDate.format('HH:mm:ss');

                return (
                  <tr key={index}>
                    <td>
                      <i className={`fas ${entry.status === 'success' ? 'fa-check text-success' : entry.status === 'warning' ? 'fa-exclamation-circle text-warning' : 'fa-ban text-danger'}`}></i> {entry.MaNV}
                    </td>
                    <td>{entry.TenNV}</td>
                    <td>{formattedTime}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Scanner;