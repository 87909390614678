import { HiXMark } from "react-icons/hi2";
import moment from 'moment';

const ApprovalHistoryModal = ({ show, onHide, history }) => {
    
  if (!show) return null;

  return (
    <div className="modal" style={{display: 'block', backgroundColor: 'rgba(0,0,0,0.5)'}}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header d-flex justify-content-between align-items-center">
        <h5 className="modal-title">Lịch Sử Duyệt</h5>
        <button type="button" className="btn btn-link p-0" onClick={onHide}>
          <HiXMark color="red" size={25}/>
        </button>
          </div>
          <div className="modal-body">
            <table className="table">
              <thead>
                <tr>
                  <th>Ngày</th>
                  <th>Nhân Viên</th> 
                  <th>Người Duyệt</th>
                  <th>Thời Gian Duyệt</th>
                </tr>
              </thead>
              <tbody>
                {history.map((item, index) => (
                  <tr key={index}>
                    <td>{moment(item.ngaydk).format('DD/MM/YYYY')}</td>
                    <td>{item.tenNhanVien}</td>
                    <td>{item.nguoiDuyet}</td>
                    <td>{moment(item.thoiGianDuyet).format('DD/MM/YYYY HH:mm')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApprovalHistoryModal;