// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDuzhm1rj443EHTQVoiKqWFmSuIMtW2FFo",
  authDomain: "pushnofitications-ct.firebaseapp.com",
  projectId: "pushnofitications-ct",
  storageBucket: "pushnofitications-ct.appspot.com",
  messagingSenderId: "218838928188",
  appId: "1:218838928188:web:0f8a79ceb10c665b13ac39",
  measurementId: "G-FK36R1CV7T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };