import React, { useState, useEffect,useMemo } from 'react';
import axios from 'axios';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { useNavigate } from 'react-router-dom';
import NavTabs from './NavTabs';
import './OvertimeApproval.css'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { messaging } from './firebase';
import { getToken } from "firebase/messaging";
import { onMessage } from "firebase/messaging";
import { API_URL } from '../authlogin/api';
import { Modal, Button } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { GrSubtract } from "react-icons/gr";
import { vapidKey } from '../authlogin/api';
document.title = "Nhập dữ liệu";

function DataEntryPage() {
  const [dateRange, setDateRange] = useState({
    startDate: moment().format('DD/MM/YYYY'),
    endDate: moment().format('DD/MM/YYYY')
  });
  // const getCurrentTime = () => {  
  //     const now = new Date();  
  //     const hours = String(now.getHours()).padStart(2, '0'); // Đảm bảo có 2 chữ số  
  //     const minutes = String(now.getMinutes()).padStart(2, '0'); // Đảm bảo có 2 chữ số  
  //     return `${hours}:${minutes}`;  
  //   };  
  //   // Khởi tạo state với giờ hiện tại  
  //   const [timeRange, setTimeRange] = useState({  
  //     startTime: getCurrentTime(),  
  //     endTime: getCurrentTime() 
  //   });
  const [startTime, setStartTime] = useState(moment('16:30', 'HH:mm').toDate());
  const [endTime, setEndTime] = useState(moment('20:00', 'HH:mm').toDate()); 
  const [employees, setEmployees] = useState([]);
  const [dates, setDates] = useState([]);
  const navigate = useNavigate();
  const [cancellationNotes, setCancellationNotes] = useState({});
  const [capDo, setCapDo] = useState('');
  const [selectAll, setSelectAll] = useState({});
  const [departmentStats, setDepartmentStats] = useState({});
  const [overtimeReasons, setOvertimeReasons] = useState('');
  const [canEnterReason, setCanEnterReason] = useState(false);
  const [overtimeHours, setOvertimeHours] = useState('1:00');
  const [Customers, setCustomers] = useState('');
  const [Productcode, setProductcode] = useState('');
  const [ProposedName, setProposedName] = useState('');
  const [nhomngaydangkybatdau, setNhomngaydangkybatdau] = useState(moment().format('YYYY-MM-DD'));
  const [nhomngaydangkyketthuc, setNhomngaydangkyketthuc] = useState(moment().format('YYYY-MM-DD'));
  const [logData, setLogData] = useState({});
  const [isLoadings, setIsLoadings] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchField, setSearchField] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [employeeToCancel, setEmployeeToCancel] = useState(null);
  const [nhaan, setNhaan] = useState(null);
  const [maynenkhi, setMaynenkhi] = useState(null);
  const [nguoiPhuTrach, setNguoiPhuTrach] = useState('');
  const [nguoiGiaoChiaKhoa, setNguoiGiaoChiaKhoa] = useState('');
  const [showTimeWarning, setShowTimeWarning] = useState(false);
  
  
  document.title = "Nhập dữ liệu";
  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedCapDo = localStorage.getItem('selectedCapDo');
    if (!token) {
      navigate('/login');
    } else {
      setCapDo(storedCapDo);
      fetchEmployees();
      checkReasonPermission();
    }
  }, []);
  useEffect(() => {
  // console.log('logData:', logData);
  if (dates.length > 0 && employees.length > 0 && Object.keys(logData).length > 0) {
    calculateDepartmentStats();
  }
}, [dates, employees, logData]);
  useEffect(() => {
    generateDates();
    fetchEmployees();
    fetchLogData();
  }, [dateRange]);

  useEffect(() => {
    calculateDepartmentStats();
  }, [employees, dates,logData]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isDataChanged) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDataChanged]);
//   useEffect(() => {
//     requestNotificationPermission();
//   }, []);
//  useEffect(() => {
//   const unsubscribe = onMessage(messaging, (payload) => {
//     console.log('Message received. ', payload);
//     toast.info(payload.data.body, {
//       onClick: () => {
//         if (payload.data.url) {
//           navigate(payload.data.url);
//         }
//       }
//     });
//   });

//   return () => {
//     unsubscribe();
//   };
// }, [navigate]);
// const getPlatform = () => {
//   const userAgent = navigator.userAgent || navigator.vendor || window.opera;
//   if (/android/i.test(userAgent)) {
//     return 'android';
//   }
//   if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
//     return 'ios';
//   }
//   return 'web';
// };
// const requestNotificationPermission = async () => {
//   try {
//     const permission = await Notification.requestPermission();
//     if (permission === 'granted') {
//       const token = await getToken(messaging, { vapidKey });
//       // console.log("Token:", token);
//       await sendTokenToServer(token);
//     } else {
//       console.log('Notification permission denied');
//     }
//   } catch (error) {
//     console.error('Error requesting notification permission:', error);
//   }
// }; 
  
//   const sendTokenToServer = async (token) => {
//     try {
//     const platform = getPlatform();
//     const response = await axios.post(`${API_URL}/register-device`, { token,platform }, {
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem('token')}`
//       }
//     });
//   } catch (error) {
//     console.error('Error sending token to server:', error);
//   }
// };

  const showNotification = (message) => {
    toast(message);
  };
  const fetchEmployees = async (start = startTime, end = endTime) => {
  try {
    const response = await axios.get(`${API_URL}/employeelist`, {
      params: {
        startDate: moment(dateRange.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        endDate: moment(dateRange.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        capDo: capDo,
        startTime: moment(start, 'HH:mm').format('HH:mm'),
        endTime: moment(end, 'HH:mm').format('HH:mm')
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    setEmployees(response.data);
    // console.log(response.data);
  } catch (error) {
    console.error('Lỗi khi lấy danh sách nhân viên:', error);
  }
};
   const fetchLogData = async () => {
    try {
      const response = await axios.get(`${API_URL}/log-data`, {
        params: {
          startDate: moment(dateRange.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          endDate: moment(dateRange.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD') 
        },
        headers: { 
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      // console.log(response.data);
      setLogData(response.data);
    } catch (error) {
      console.error('Lỗi khi lấy dữ liệu log:', error);
    }
  };
  const generateDates = () => {
    const start = moment(dateRange.startDate, 'DD/MM/YYYY');
    const end = moment(dateRange.endDate, 'DD/MM/YYYY');
    const dateArray = [];
    let currentDate = start.clone();

    while (currentDate.isSameOrBefore(end)) {
      dateArray.push(currentDate.format('DD/MM/YYYY'));
      currentDate.add(1, 'days');
    }
    setDates(dateArray);

    const initialSelectAll = {};
    dateArray.forEach(date => {
      initialSelectAll[date] = { tangCa: false };
    });
    setSelectAll(initialSelectAll);
  };

  const handleDateChange = (event, picker) => {
  setDateRange({
    startDate: picker.startDate.format('DD/MM/YYYY'),
    endDate: picker.endDate.format('DD/MM/YYYY')
  });
  setNhomngaydangkybatdau(picker.startDate.format('YYYY-MM-DD'));
  setNhomngaydangkyketthuc(picker.endDate.format('YYYY-MM-DD'));
  setIsDataChanged(true);
};
  const checkReasonPermission = async () => {
    try {
      const response = await axios.get(`${API_URL}/check-reason-permission`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setCanEnterReason(response.data.canEnterReason);
    } catch (error) {
      console.error('Error checking reason permission:', error);
    }
  };
  const handleOvertimeChange = (employeeId, date, meal, value) => {
    setEmployees(employees.map(emp => {
      if (emp.id === employeeId) {
        const overtime = {...emp.overtime};
        if (!overtime[date]) overtime[date] = {};
        overtime[date][meal] = value;
        return {...emp, overtime};
      }
      return emp;
    }));
    setIsDataChanged(true); // Set to true when data changes

    // Clear reason when overtime is unchecked
    if (!value) {
      setOvertimeReasons(prev => {
        const updated = {...prev};
        if (updated[employeeId] && updated[employeeId][date]) {
          delete updated[employeeId][date];
        }
        return updated;
      });
    }
  };
  
  const handleSaveData = async () => {
  if (isLoading) return;
    setIsLoading(true);
    // Kiểm tra xem có đăng ký nào không
  const hasRegistrations = employees.some(emp => 
    Object.values(emp.overtime).some(meal => meal.tangCa && !meal.approved)
  );
  if(showTimeWarning){
    alert('Thời gian làm thêm không hợp lệ. Vui lòng chọn lại thời gian.');
    setIsLoading(false);
    return;
  }
  if (!hasRegistrations) {
    alert('Không có đăng ký nào để lưu. Vui lòng đăng ký trước khi lưu.');
    setIsLoading(false);
    return;
  }
  if (canEnterReason && !overtimeReasons.trim()) {
    alert('Vui lòng nhập lý do ăn ca trước khi lưu.');
    setIsLoading(false);
    return;
  }
  if (canEnterReason && !ProposedName.trim()) {
    alert('Vui lòng nhập tên đề xuất trước khi lưu.');
    setIsLoading(false);
    return;
    }
    if(canEnterReason && nhaan===null){
      alert('Vui lòng chọn nhà ăn trước khi lưu.');
      setIsLoading(false);
      return;
    }
    if(canEnterReason && maynenkhi===null){
      alert('Vui lòng chọn máy nén khí trước khi lưu.');
      setIsLoading(false);
      return;
    }
  try {
    const employeesToSave = employees.filter(emp => 
      Object.values(emp.overtime).some(meal => meal.tangCa && !meal.approved)
    );
    const formattedStartTime = moment(startTime).format('HH:mm');
    const formattedEndTime = moment(endTime).format('HH:mm');
    await axios.post(`${API_URL}/save-attendance`, 
      { 
        employees: employeesToSave, 
        overtimeReasons,
        startTime: formattedStartTime,
        endTime: formattedEndTime,
        Productcode,
        Customers,
        ProposedName,
        nhomngaydangkybatdau,
        nhomngaydangkyketthuc,
        nhaan,
        maynenkhi
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
    showNotification('Dữ liệu đã được lưu thành công!');
    setIsDataChanged(false);
    fetchEmployees();
    fetchLogData();
    calculateDepartmentStats();
  } catch (error) {
    console.error('Error saving data:', error);
    alert('Có lỗi xảy ra khi lưu dữ liệu');
  } finally {
    setIsLoading(false);
  }
};

  // Cập nhật hàm handleCancel
const handleCancel = (employeeId) => {
  const datesToCancel = dates.filter(date => 
    employees.find(emp => emp.id === employeeId)?.overtime?.[date]?.tangCa &&
    !employees.find(emp => emp.id === employeeId)?.overtime?.[date]?.approved &&
    employees.find(emp => emp.id === employeeId)?.overtime?.[date]?.trangthai !== 'huỷ'
  );
  
  if (datesToCancel.length === 0) {
    alert('Không có ngày nào để hủy');
    return;
  }
  
  setEmployeeToCancel({ id: employeeId, dates: datesToCancel });
  setShowConfirmModal(true);
};

// Cập nhật hàm confirmCancel
const confirmCancel = async () => {
  if (employeeToCancel) {
    try {
      await axios.post(`${API_URL}/cancel-overtime`, 
        { employeeId: employeeToCancel.id, dates: employeeToCancel.dates },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      showNotification('Đã hủy đăng ký tăng ca thành công');
      fetchEmployees();
      fetchLogData();
      calculateDepartmentStats();
    } catch (error) {
      console.error('Lỗi khi hủy đăng ký tăng ca:', error);
      alert('Có lỗi xảy ra khi hủy đăng ký tăng ca');
    }
    setShowConfirmModal(false);
  }
};
// Hàm kiểm tra thời gian
  const validateTimeRange = (start, end) => {
    const startTime = moment(start, 'HH:mm');
    const endTime = moment(end, 'HH:mm');

    // Nếu thời gian kết thúc nhỏ hơn thời gian bắt đầu, giả định là qua ngày mới
    if (endTime.isBefore(startTime)) {
      endTime.add(1, 'day');
    }

    // Kiểm tra nếu khoảng thời gian vượt quá 12 giờ
    return endTime.diff(startTime, 'hours') <= 12;
  };


  const handleStartTimeChange = (date) => {
    setStartTime(date);
    setIsDataChanged(true);
    const newStartTime = moment(date).format('HH:mm');

    setShowTimeWarning(!validateTimeRange(newStartTime, moment(endTime).format('HH:mm')));
    fetchEmployees(newStartTime, moment(endTime).format('HH:mm'));
  };
  
  const handleEndTimeChange = (date) => {
    setEndTime(date);
    setIsDataChanged(true);
    const newEndTime = moment(date).format('HH:mm');
    setShowTimeWarning(!validateTimeRange(moment(startTime).format('HH:mm'), newEndTime));
    fetchEmployees(moment(startTime).format('HH:mm'), newEndTime);
  };
  // const handleCustomersChange = () => {
  //   setCustomers(e.target.value);
  // };
  // const handleProductcodeChange = () => {
  //   setProductcode(e.target.value);
  // };
  const handleSelectAll = (date, meal) => {
    const newSelectAll = {...selectAll};
    newSelectAll[date][meal] = !newSelectAll[date][meal];
    setSelectAll(newSelectAll);

    setEmployees(employees.map(emp => {
      const overtime = {...emp.overtime};
      if (!overtime[date]) overtime[date] = {};
      overtime[date][meal] = newSelectAll[date][meal];
      return {...emp, overtime};
    }));
  };
 const calculateDepartmentStats = () => {
  const stats = {};

  // Initialize stats based on employees data (only checked ones)
  employees.forEach(emp => {
    dates.forEach(date => {
      const formattedDate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
      if (emp.overtime?.[date]?.tangCa) {
        if (!stats[emp.BoPhan]) stats[emp.BoPhan] = {};
        if (!stats[emp.BoPhan][formattedDate]) {
          stats[emp.BoPhan][formattedDate] = { registered: 0, checked: 0 };
        }
        stats[emp.BoPhan][formattedDate].checked++;
      }
    });
  });

  // Update registered count from logData
  if (Array.isArray(logData)) {
    logData.forEach(log => {
      const date = log.date;
      if (stats[log.tenBoPhan] && stats[log.tenBoPhan][date]) {
        stats[log.tenBoPhan][date].registered = log.soluong;
      }
    });
  }

  // Remove departments with no checks
  Object.keys(stats).forEach(boPhan => {
    const hasChecks = Object.values(stats[boPhan]).some(date => date.checked > 0);
    if (!hasChecks) {
      delete stats[boPhan];
    }
  });

  setDepartmentStats(stats);
};

  const ExcelJS = require('exceljs');
  
  const exportToExcel = async () => {
    let filteredEmployees = employees;
    let fileName = 'Van ban thoa thuan lam them gio';
    

    const startDate = moment(dateRange.startDate, 'DD/MM/YYYY');
    const endDate = moment(dateRange.endDate, 'DD/MM/YYYY');
    const dayDiff = endDate.diff(startDate, 'days') + 1;
    
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Overtime');

    // Set column widths
    worksheet.columns = [
        { width: 5 }, { width: 8 }, { width: 28 }, { width: 13 }, { width: 22 },
        { width: 10 }, { width: 10 }, { width: 10 },
        ...Array(dayDiff * 2).fill({ width: 10 })
      ];

    // Add header rows
    worksheet.addRow(['CÔNG TY CỔ PHẦN MAY CÔNG TIẾN', '', '', '',  'CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM','']);
    worksheet.addRow(['PHÒNG NHÂN SỰ', '', '', '',   'Độc lập - Tự do - Hạnh phúc']);
    worksheet.addRow([]);
    worksheet.addRow(['VĂN BẢN THỎA THUẬN LÀM THÊM GIỜ']);
    worksheet.addRow([]);
    worksheet.addRow([`Thời gian làm thêm: Kể từ ngày ${dateRange.startDate} đến ngày ${dateRange.endDate}`]);
    worksheet.addRow(['- Địa điểm làm thêm: Công ty cổ phần May Công Tiến - 27 Mạc Văn Thành, KP.6, P.2, T.P Gò Công, Tiền Giang.']);
    worksheet.addRow(['- Lý do làm thêm: đáp ứng tiến độ sản xuất.']);
    worksheet.addRow([]);

    // Add table headers
    const headers = ['STT', 'SỐ THẺ', 'HỌ TÊN', 'Bộ phận', 'Chức danh công việc', 'Số giờ làm việc trong ngày', 'Số giờ trong giờ', 'Số giờ làm thêm trong ngày', 'Ký tên đăng ký tự nguyện làm thêm giờ'];
    worksheet.addRow(headers);
    worksheet.getColumn(7).hidden = true;
    // Add date row
    const dateRow = ['', '', '', '', '', '', '', ''];
    for (let i = 0; i < dayDiff; i++) {
      const currentDate = moment(startDate).add(i, 'days');
      dateRow.push(currentDate.format('DD/MM'));
    }
    worksheet.addRow(dateRow);
    // Calculate overtime hours
    const startMoment = moment(startTime, 'HH:mm');
    const endMoment = moment(endTime, 'HH:mm');
    const duration = moment.duration(endMoment.diff(startMoment));
    const overtimeHours = duration.asHours();
    
    // Add employee data
    filteredEmployees.forEach((emp, index) => {
      const row = [
        index + 1,
        emp.MaNV,
        emp.TenNV,
        emp.BoPhan,
        emp.CongViecPhuTrach || '',
      ];
      // Calculate hours based on NCN status
      const regularHours = emp.NCN ? 7 : 8;
      const totalHours = regularHours + overtimeHours;

      row.push(
        totalHours.toFixed(2),  // số giờ làm việc trong ngày, làm tròn đến 2 chữ số thập phân
        regularHours.toString(),  // số giờ trong giờ
        overtimeHours.toFixed(2)  // số giờ làm thêm trong ngày
      );
      for (let i = 0; i < dayDiff; i++) {
        const currentDate = moment(startDate).add(i, 'days');
        const dateStr = currentDate.format('DD/MM/YYYY');
        const overtimeForDay = emp.overtime?.[dateStr]?.tangCa;
        row.push(overtimeForDay ? '' : '.');
      }

      const Row = worksheet.addRow(row);
      Row.height = 35;
      Row.alignment = { vertical: 'middle' };
      Row.getCell(5).alignment = { vertical: 'middle', wrapText: true };
      Row.getCell(4).alignment = { vertical: 'middle', wrapText: true };
    });

    // Add footer
    worksheet.addRow([]);
    worksheet.addRow(['', '', '', '', '',  'Ngày              Tháng            Năm']);
    worksheet.addRow(['', '', '', '', '',  'GIÁM ĐỐC CÔNG TY']);

    // Apply styles
    worksheet.eachRow((row, rowNumber) => {
      row.eachCell((cell) => {
        cell.font = { name: 'Times New Roman', size: 11 };
        if (rowNumber >= 10 && rowNumber <= 11 + filteredEmployees.length) {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
          }; 
          if (cell.value === '.') {
              cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
              cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
              diagonal: { 
                up: true,     // Đường chéo từ góc dưới bên trái đến góc trên bên phải
                down: true,   // Đường chéo từ góc trên bên trái đến góc dưới bên phải
                style: 'thin' // Kiểu đường viền
              }
            };
            cell.value = '';
            }
      }
        
      });

      if (rowNumber === 1 || rowNumber === 2) {
        row.getCell(1).font = { bold: true, name: 'Times New Roman', size: 12 };
        row.getCell(5).font = { bold: true, name: 'Times New Roman', size: 12 };
        row.getCell(5).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      }
      if (rowNumber === 2) {
        row.getCell(5).font = {  name: 'Times New Roman', size: 12, underline: true };
        row.getCell(5).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    }
      if (rowNumber === 4) {
        row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        row.getCell(1).font = { bold: true, name: 'Times New Roman', size: 20 };
      }
      if (rowNumber === 10) {
          row.eachCell((cell) => {
            cell.font = { bold: true, name: 'Times New Roman', size: 12 };
            cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
          });
        }
      if (rowNumber === 10) {
        const startCol = 9;
        const endCol = startCol + dayDiff - 1;
        worksheet.mergeCells(rowNumber, startCol, rowNumber, endCol);
        const mergedCell = row.getCell(startCol);
        mergedCell.value = 'Ký tên đăng ký tự nguyện làm thêm giờ';
        mergedCell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      }
    

      if (rowNumber === 11) {
        row.eachCell((cell, colNumber) => {
          if (colNumber > 8) {
            cell.font = { bold: true, name: 'Times New Roman', size: 11 };

          }
        });
      }
    });
    

    // Merge cells
    worksheet.mergeCells('A1:D1');
    worksheet.mergeCells('E1:J1');
    worksheet.mergeCells('A2:D2');
    worksheet.mergeCells('E2:J2');
    worksheet.mergeCells('A4:K4');
    worksheet.mergeCells('A10:A11');
    worksheet.mergeCells('B10:B11');
    worksheet.mergeCells('C10:C11');
    worksheet.mergeCells('D10:D11');
    worksheet.mergeCells('E10:E11');
    worksheet.mergeCells('F10:F11');
    worksheet.mergeCells('G10:G11');
    worksheet.mergeCells('H10:H11');
    
    // Generate buffer
    const buffer = await workbook.xlsx.writeBuffer();

    // Create Blob
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    // Create download link and trigger download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}_${dateRange.startDate}-${dateRange.endDate}.xlsx`;
    link.click();
    
    // Clean up
    URL.revokeObjectURL(link.href);
  };
  const canCancel = (employee) => {
    const hasUncancelableOvertime = Object.values(employee.overtime).some(
      day => day.capdoduyethientai >= 2
    );
    return !hasUncancelableOvertime && employee.tinhTrang !== 'Đã hủy';
    };
  const sortedEmployees = [...employees].sort((a, b) => a.MaNV.localeCompare(b.MaNV));
const handleHeaderClick = (field) => {
  setSearchField(field);
  setSearchTerm('');
};
  
const handleSearch = (e) => {
  setSearchTerm(e.target.value);
};
const filteredEmployees = useMemo(() => {
  return sortedEmployees.filter(employee => {
    if (!searchTerm) return true;
    
    const normalizedSearchTerm = searchTerm.toLowerCase().trim();
    
    if (searchField === 'MaNV') {
      return employee.MaNV.toLowerCase().includes(normalizedSearchTerm);
    } else if (searchField === 'TenNV') {
      return employee.TenNV.toLowerCase().includes(normalizedSearchTerm);
    }else if (searchField === 'BoPhan') {
      return employee.BoPhan.toLowerCase().includes(normalizedSearchTerm);
    }
    
    return true;
  });
}, [sortedEmployees, searchTerm, searchField]);
  const CustomTimeInput = ({ date, value, onChange }) => (
    <input
      type="time"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      style={{ border: '1px solid #ced4da', borderRadius: '0.25rem', padding: '0.375rem 0.75rem' }}
      className="form-control"
    />
  );
  return (
    <>
      <NavTabs/>
    <div className="overtime-approval py-4">
      <div className="row g-3">
        <div className="col-xl-3 col-md-6">
          <label className='form-label fw-bold'>Chọn khoảng thời gian:</label>
          <DateRangePicker
            initialSettings={{
              startDate: dateRange.startDate,
              endDate: dateRange.endDate,
              locale: {
                format: 'DD/MM/YYYY'
              },
              minDate: moment().toDate(),
              maxDate: moment().add(10, 'days').toDate()
            }}
            onApply={handleDateChange}
          >
            <input type="text" className="form-control" value={`${dateRange.startDate} - ${dateRange.endDate}`} readOnly />
          </DateRangePicker>
        </div>
          
        <div className="col-xl-3 col-md-6 ">
        <label className='form-label fw-bold '>Chọn giờ làm thêm:</label>
        <div className="d-flex me-3">
          <DatePicker
            selected={startTime}
            onChange={handleStartTimeChange}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Chọn giờ"
            dateFormat="HH : mm"
            timeFormat="HH : mm"
            customTimeInput={<CustomTimeInput />}
            className="form-control me-3"
          />
          
          <GrSubtract className="align-self-center mx-3" size={20}  />
          <DatePicker
            selected={endTime}
            onChange={handleEndTimeChange}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Chọn giờ"
            dateFormat="HH : mm"
            timeFormat="HH : mm"
            customTimeInput={<CustomTimeInput />}
            className="form-control me-2"
          />
        </div>
        
          </div>
          {showTimeWarning && (
            <Alert variant="danger" className="mt-2">
              Cảnh báo: Thời gian làm thêm không hợp lý. Vui lòng chọn lại.
            </Alert>
          )}
          <div className='reason'>
          {canEnterReason && (
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="card-title mb-4">Thông tin chi tiết</h5>
                <div className="row g-3">
                  <div className='col-md-6'>
                    <label className="form-label">Lý do ăn ca:</label>
                    <input 
                      type="text"
                      className="form-control ld" 
                      value={overtimeReasons}
                      onChange={(e) => setOvertimeReasons(e.target.value)}
                      placeholder="Nhập lý do ăn ca*" 
                    />
                  </div>
                  <div className='col-md-6'>
                    <label className="form-label ">Tên đề xuất:</label>
                    <input 
                      type="text"
                      className="form-control ld"
                      value={ProposedName}
                      onChange={(e) => setProposedName(e.target.value)}
                      placeholder="Nhập tên đề xuất"
                    />
                  </div>
                  <div className='col-md-6'>
                    <label className="form-label">Mã hàng:</label>
                    <input 
                      type="text"
                      className="form-control" 
                      value={Productcode}
                      onChange={(e) => setProductcode(e.target.value)}
                      placeholder="Nhập mã hàng"
                    />
                  </div>
                  <div className='col-md-6'>
                    <label className="form-label">Khách hàng:</label>
                    <input 
                      type="text"
                      className="form-control" 
                      value={Customers}
                      onChange={(e) => setCustomers(e.target.value)}
                      placeholder="Nhập khách hàng"
                    />
                  </div>
                  {/* <div className='col-md-6'>
                    <label className="form-label">Người phụ trách:</label>
                    <textarea 
                      className="form-control ld" 
                      value={nguoiPhuTrach}
                      onChange={(e) => setNguoiPhuTrach(e.target.value)}
                      placeholder="Nhập tên người phụ trách"
                      rows="2"
                    />
                  </div>
                  <div className='col-md-6'>
                    <label className="form-label">Giao chìa khóa:</label>
                    <textarea 
                      className="form-control ld" 
                      value={nguoiGiaoChiaKhoa}
                      onChange={(e) => setNguoiGiaoChiaKhoa(e.target.value)}
                      placeholder="Nhập tên người giao chìa khóa"
                      rows="2"
                    />
                  </div> */}
                  <div className='col-md-3'>
                    <label className="form-label d-block">Nhà ăn:</label>
                    <div className="btn-group w-100" role="group">
                      <input 
                        type="radio" 
                        className="btn-check" 
                        name="nhaan" 
                        id="nhaan-co" 
                        checked={nhaan === true}
                        onChange={() => setNhaan(true)}
                      />
                      <label className="btn btn-outline-primary" htmlFor="nhaan-co">Có</label>
                      <input 
                        type="radio" 
                        className="btn-check" 
                        name="nhaan" 
                        id="nhaan-khong" 
                        checked={nhaan === false}
                        onChange={() => setNhaan(false)}    
                      />
                      <label className="btn btn-outline-primary" htmlFor="nhaan-khong">Không</label>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <label className="form-label d-block">Máy nén khí:</label>
                    <div className="btn-group w-100" role="group">
                      <input 
                        type="radio" 
                        className="btn-check" 
                        name="maynenkhi" 
                        id="maynenkhi-co" 
                        checked={maynenkhi === true}
                        onChange={() => setMaynenkhi(true)}
                      />
                      <label className="btn btn-outline-primary" htmlFor="maynenkhi-co">Có</label>
                      <input 
                        type="radio" 
                        className="btn-check" 
                        name="maynenkhi" 
                        id="maynenkhi-khong" 
                        checked={maynenkhi === false}
                        onChange={() => setMaynenkhi(false)}
                      />
                      <label className="btn btn-outline-primary" htmlFor="maynenkhi-khong">Không</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        </div>

        <div className="row mt-4">
          <div className="col-12">
            <div className="d-flex gap-2">
              <button className="btn btn-primary" onClick={handleSaveData}
              disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    Đang lưu...
                  </>
                ) : 'Lưu dữ liệu'}</button>
              <button 
                className="btn btn-success" 
                onClick={exportToExcel}
                disabled={isLoadings}
              >
                {isLoadings ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    Đang xuất...
                  </>
                ) : 'Xuất excel ký tên'}
              </button>
            </div>
          </div>
        </div>
      
      <div className="mt-4">
          <h3 className="mb-3">Thống kê đăng ký theo bộ phận</h3>
          <div className="table-responsive">
            <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th rowSpan="2">Bộ phận</th>
              {dates.map(date => (
                <th key={date} colSpan="2">{date}</th>
              ))}
            </tr>
            <tr>
              {dates.map(date => (
                <React.Fragment key={date}>
                  <th>Đã check chọn</th>
                  <th>Số lượng đã đăng ký</th>
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.entries(departmentStats)
              .sort(([a], [b]) => a.localeCompare(b))
              .map(([boPhan, dateData]) => (
                <tr key={boPhan}>
                  <td>{boPhan}</td>
                  {dates.map(date => {
                    const formattedDate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
                    const data = dateData[formattedDate] || { checked: 0, registered: 0 };
                    return (
                      <React.Fragment key={date}>
                        <td>{data.checked}</td>
                        <td>{data.registered}</td>
                      </React.Fragment>
                    );
                  })}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      </div>

      <div className="employee-table mt-4">    
      {searchField && (
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder={`Tìm kiếm theo ${
                searchField === 'MaNV' ? 'Mã NV' : 
                searchField === 'TenNV' ? 'Họ Tên' : 
                searchField === 'BoPhan' ? 'Bộ Phận' : ''
              }`}
                value={searchTerm}
                onChange={handleSearch} 
              />
            </div>
          )}
        <table className="table table-bordered">
          <thead>
            <tr>
              <th rowSpan="2">STT</th>
              <th rowSpan="2" onClick={() => handleHeaderClick('MaNV')} style={{cursor: 'pointer'}}>Mã NV 🔎</th>
              <th rowSpan="2" onClick={() => handleHeaderClick('TenNV')} style={{cursor: 'pointer'}}>Họ Tên 🔎</th>
              <th rowSpan="2" onClick={() => handleHeaderClick('BoPhan')} style={{cursor: 'pointer'}}>Bộ Phận 🔎</th>
              <th rowSpan="2">Phòng Ban</th>
              {dates.map(date => (
                <th key={date} colSpan="1">{date}</th>
              ))}
              <th rowSpan="2">Thao tác</th>
            </tr>
            <tr>
              {dates.map(date => (
                <React.Fragment key={date}>
                  <th>
                    Tất cả
                    <input
                      type="checkbox"
                      checked={selectAll[date]?.tangCa || false}
                      onChange={() => handleSelectAll(date, 'tangCa')}
                      className='ms-1 position-absolute '
                      
                    />
                  </th>
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredEmployees.map((employee, index) => (
              <tr key={employee.id}>
                <td>{index + 1}</td>
                <td>{employee.MaNV}</td>
                <td>{employee.TenNV}</td>
                <td>{employee.BoPhan}</td>
                <td>{employee.PhongBan}</td>
                
              {dates.map(date => (
                <React.Fragment key={date}>
                  <td>
                    <input
                      type="checkbox"
                      checked={employee.overtime?.[date]?.tangCa || false}
                      onChange={(e) => handleOvertimeChange(employee.id, date, 'tangCa', e.target.checked)}
                      disabled={employee.approved || employee.overtime?.[date]?.trangthai === 'huỷ'}
                    />
                    {employee.overtime?.[date]?.isTimeConflict &&(
                    <span style={{ color: 'red', marginLeft: '5px' }}>
                      ⚠️ Đã đk {employee.overtime[date].startTime} - {employee.overtime[date].endTime}
                    </span>
                  )}
                  </td>
                </React.Fragment>
              ))}
                <td>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => handleCancel(employee.id)}
                    disabled={!canCancel(employee)}
                  >
                    Huỷ
                  </button>
                </td>
              </tr>
            ))}
              <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Xác nhận hủy</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>Bạn có chắc chắn muốn hủy đăng ký tăng ca này?</Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                      Hủy bỏ
                    </Button>
                    <Button variant="danger" onClick={confirmCancel}>
                      Xác nhận hủy
                    </Button>
                  </Modal.Footer>
                </Modal>
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
    </>
  );
}

export default DataEntryPage;